/* General Page Styling */
.page-container {
  font-family: "Manjari", sans-serif;
  padding: 40px;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 100px;
  text-align: left;
}

/* Section Styling */
.section {
  margin-bottom: 60px;
}

/* Opportunities Section */
.opportunities h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.opportunity-list {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Opportunity Cards */
.opportunity {
  width: 32%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-left: 5px solid rgb(150, 178, 61);
}

.opportunity:hover {
  transform: translateY(-5px);
}

.opportunity h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: rgb(70, 80, 30);
}

.opportunity p {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
  line-height: 1.5;
}

.opportunity-button {
  padding: 12px 18px;
  background-color: rgb(150, 178, 61);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
}

.opportunity-button:hover {
  background-color: #5a6b24;
}

/* Chatbot Container */
.chatbot-container {
  background: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

/* Chat Messages */
.chat-container {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 12px;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.message {
  padding: 12px 18px;
  margin-bottom: 12px;
  border-radius: 8px;
  font-size: 14px;
}

.message.bot {
  background-color: #e9ecef;
  color: #333;
}

.message.user {
  background-color: rgb(197, 227, 151);
  text-align: right;
  color: #333;
}

/* Chat Input */
.chat-input-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.chat-input-container input {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.chat-input-container button {
  padding: 12px 18px;
  background-color: rgb(150, 178, 61);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease-in-out;
}

.chat-input-container button:hover {
  background-color: #5a6b24;
}

/* Partnership Dropdown */
.partnership-dropdown {
  margin-top: 30px;
  background: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.partnership-dropdown label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  color: #333;
}

.partnership-dropdown select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Error Message */
.error-message {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

@import url('https://fonts.googleapis.com/css2?family=Manjari:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Manjari', sans-serif;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 70px;
  background-color: white; /* Slightly transparent */
  backdrop-filter: blur(5px); /* Adds a blur effect */
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.header.hidden {
  transform: translateY(-100%); /* Move header out of view when hidden */
}

.header.visible {
  transform: translateY(0); /* Reset to default position when visible */
}

.logo {
  display: flex; /* Aligns the image and text side by side */
  align-items: center; /* Vertically centers the text with the image */
  gap: 8px; /* Adds spacing between the image and the text */
}

.logo img {
  width: 92px; /* Makes the image small */
  height: auto; /* Maintains aspect ratio */
}

.logo span {
  font-size: 1.5rem; /* Adjust text size */
  font-weight: 700; /* Makes the text bold */
  color: #333; /* Sets the text color */
  font-family: 'League Spartan', sans-serif;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  margin: 0 15px;
  font-size: 1rem;
  font-weight: 400;
  transition: color 0.3s;
  font-family: 'Manjari', sans-serif;
}

.nav-links a:hover {
  color: rgb(150, 178, 61); /* Change color on hover */
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack items vertically */
    padding: 10px 20px;
  }

  .logo span {
    font-size: 1.2rem; /* Smaller text for logo */
  }

  .nav-links {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center links */
    gap: 10px; /* Add space between links */
    margin-top: 10px;
  }

  .nav-links a {
    margin: 0; /* Remove horizontal margins */
    font-size: 0.9rem; /* Slightly smaller font size */
  }

  .logo img {
    width: 72px; /* Smaller logo image */
  }
}

/* Further Responsive Adjustments for Tiny Screens */
@media (max-width: 480px) {
  .header {
    padding: 10px 15px; /* Reduce padding for smaller screens */
    align-items: flex-start; /* Align items to the left */
  }

  .logo {
    flex-direction: column; /* Stack logo text and image */
    align-items: flex-start; /* Align to the left */
    gap: 5px; /* Less gap for smaller screens */
  }

  .logo span {
    font-size: 1rem; /* Smaller text size for tiny screens */
  }

  .logo img {
    width: 60px; /* Further reduce logo size */
  }

  .nav-links {
    align-items: flex-start; /* Align links to the left */
    margin-top: 5px; /* Smaller gap from logo */
    gap: 5px; /* Reduce gap between links */
  }

  .nav-links a {
    font-size: 0.8rem; /* Make links even smaller */
  }

  body {
    font-size: 14px; /* Adjust overall font size for readability */
  }
}
